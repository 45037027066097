import React from "react"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from "./pages/homepage"
export default function App() {
  return (
    <Router>
    <Routes>
      <Route exact path="/*" element={<HomePage></HomePage>} />
    </Routes>
    </Router>
  )
}