import React from "react";
import '../styles/style.css'
import headerLogo from "../assets/logo.png"
import headerLogoMob from "../assets/logo-m.png"
import pitch1 from "../assets/pitch.png"
import strategies from "../assets/strategies.png"
import actar1 from "../assets/actar1.png"
import actar2 from "../assets/actar2.png"
import actar3 from "../assets/actar3.png"
import actar4 from "../assets/actar4.png"
import actar5 from "../assets/actar5.png"
import actar6 from "../assets/actar6.png"
import footballpitch from "../assets/football-pitch.png"
export default function HomePage() {
  return (
    <div >

      <header>
        <div class="container">
          <div class="header-wrapper">
            <img src={headerLogo} alt="Machine football logo" />
            <img src={headerLogoMob} alt="Machine football logo" class="logo-m" />
          </div>
        </div>
      </header>

      <section class="introduction">
        <div class="container">
          <div class="introduction-wrapper">
            <div class="introduction-left">
              <h2>An introduction</h2>
            </div>
            <div class="introduction-right">
            </div>
          </div>
        </div>
      </section>

      <section class="football-thinking">
        <div class="container">
          <div class="football-thinking-wrapper">
            <h2>Machine Football provides the foundations for new football thinking.</h2>
            <img src={pitch1} alt="Football Pitch" />
          </div>
        </div>
      </section>

      <section class="pitch-strategies">
        <div class="container">
          <div class="pitch-strategies-wrapper">
            <h2>We help owners, investors and sporting directors to create winning on and off pitch strategies.</h2>
            <img src={strategies} alt="Player Attribute values" />
          </div>
        </div>
      </section>


      <section class="years-of-data">
        <div class="container">
          <div class="years-of-data-wrapper">
            <h2>We are a team of experts combined with an intelligent Ai system, trained on every professional league in the world over the last 8 years of data.</h2>
          </div>
        </div>
        <div class="avtar-details">
          <div class="actar-details-wrapper">
            <div class="actar-image">
              <img src={actar1} alt="Chris Perry" />
            </div>
            <div class="actar-details-only">
              <h4>Chris Perry</h4>
              <span>CEO</span>
              <strong>Razorfish, WPP, LemonX</strong>
            </div>
          </div>
          <div class="actar-details-wrapper">
            <div class="actar-image">
              <img src={actar2} alt="Graeme Le Saux" />
            </div>
            <div class="actar-details-only">
              <h4>Graeme Le Saux</h4>
              <span>Sporting & technical</span>
              <strong>Chelsea, Blackburn, England, Real Majorca, PFA, Premier League, UEFA, NBC, BCC</strong>
            </div>
          </div>
          <div class="actar-details-wrapper">
            <div class="actar-image">
              <img src={actar3} alt="Mary Keane-Dawson" />
            </div>
            <div class="actar-details-only">
              <h4>Mary Keane-Dawson</h4>
              <span>Exec chair</span>
              <strong>Takumi, WPP, Timeout</strong>
            </div>
          </div>
          <div class="actar-details-wrapper">
            <div class="actar-image">
              <img src={actar4} alt="Mandhir Gidda" />
            </div>
            <div class="actar-details-only">
              <h4>Mandhir Gidda</h4>
              <span>CTO & CIO</span>
              <strong>Razorfish, WPP, Valtech, Telefonica, Uber</strong>
            </div>
          </div>
          <div class="actar-details-wrapper">
            <div class="actar-image">
              <img src={actar5} alt="Gerry Coulter" />
            </div>
            <div class="actar-details-only">
              <h4>Gerry Coulter</h4>
              <span>Finance</span>
              <strong>Global Finance Director Accenture Technology</strong>
            </div>
          </div>
          <div class="actar-details-wrapper">
            <div class="actar-image">
            <img src={actar6} alt="Sahibzada Allahyar" />
            </div>
            <div class="actar-details-only">
              <h4>Sahibzada Allahyar</h4>
              <span>Quant & AI</span>
              <strong>Cambridge Imperial</strong>
            </div>
          </div>
        </div>
      </section>


      <section class="about-football">
        <div class="container">
          <div class="about-football-wrapper">
            <h2>This is not about data or technology. It’s about football</h2>
            <img src={footballpitch} alt="football pitch" />
          </div>
        </div>
      </section>


      <section class="contact">
        <div class="container">
          <div class="contact-wrapper">
            <div class="contact-left">
            </div>
            <div class="contact-right">
              <h2>Contact</h2>
            </div>
          </div>
        </div>
      </section>


      <section class="nearer-the-time">
        <div class="container">
          <div class="nearer-the-time-wrapper">
            <h4>If you would like get in touch or schedule a product demo, drop us a line at <a href="mailto:hello@machinefootball.com">hello@machinefootball.com</a>.</h4>
          </div>
        </div>
      </section>

    </div>

  );
}